:root {
    --text-color: rgb(46, 2, 90);
    --code-result-header-background: rgb(92, 92, 92);
}

body, html {
    font-family: sans-serif;
    margin: 0px;
    height: 100%;
    font-family: "Montserrat";
}
.logo {
    top: 0px;
    position: absolute;
    display: inline;
    width: 5em;
    height: auto;
    left: 10px;
}
.menu-item {
    display: inline-block;
    margin-left: 1em;
    margin-right: 1em;
    position: relative;
    top: 40%;
}
.lnk {
    text-decoration: none;
    color: rgb(220, 220, 220);
}
.hamburger-icn {
    font-size: 3em;
    margin-right: 84px;
    height: min-content;
}
@keyframes hor-menu-item-open-animate {
    from {
        margin-top: -42px;
    }
    to {
        margin-top: 0px;
    }
}
@keyframes hor-menu-item-close-animate {
    from {
        margin-top: 0px;
    }
    to {
        margin-top: -42px;
    }
}

.main-screen-root-div {
    position: relative;
    text-align: center;
    width: 75%;
    margin-top: 2em;
    left: 20%;
}

textarea {
    min-height: 30em;
    height: 30em;
    resize: vertical;
    width: 90%;
    border-radius: 7px;
    background-color: #e6e6e6;
    border: solid 1px;
    padding: 10px;
    font-size: 15px;
    position: relative;
    left: 5%;
}

.process-btn {
    padding: 10px;
    padding-right: 25px;
    padding-left: 25px;
    font-size: 18px;
    font-family: Montserrat;
    background-color: #3bb43b;
    border-radius: 5px;
    border: none;
    color: white;
    cursor: pointer;
}

.sidebar > div {
    color: var(--text-color)
}

.ps-menu-button {
    color: var(--text-color)
}

.intro-div {
    position: relative;
    width: 58%;
    display: inline-block;
    text-align: center;
    font-size: 20px;
    line-height: 40px;
    color: --text-color;
    font-family: Montserrat;
    left: 30%;
    margin-top: 4em;
}

.msgBx {
    padding: 2%;
    margin-top: 5%;
    margin-bottom: 5%;
    font-size: 20px;
    font-family: Montserrat;
    color: --text-color;
    border-radius: 5px;
}

.ok {
    background-color: #5ea45e;
}

.not-ok {
    background-color: #bc3939;
}

pre {
    display: contents;
    margin-top: 0;
    margin-bottom: 0.5rem !important;
    overflow: visible !important;
    font-size: .875em;
    white-space: pre;
}

.codePre {
    display: inline;
    white-space: pre-wrap;
}

.evenPre {
    background-color: white;
    width: inherit;
}

.oddPre {
    background-color: #cfcdcd;
    width: inherit;
}

.code-span {
    display: inline-block;
    width: 5%;
    padding-right: 2em;
    align-self: stretch;
    background-color: rgb(207, 207, 207);
}

.code-code {
    display: inline-block;
    width: max-content;
}

.result-header {
    background-color: var(--code-result-header-background);
    color: --text-color;
    margin: 0 auto;
    padding-top: 1em;
    padding-bottom: 1em;
    display: flex;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.tree-span {
    margin: auto;
    margin-right: 20px;
    font-size: 12px;
}

.compare-textarea {
    min-width: 40%;
    width: 20%;
}

.json-compare-div {
    display: flex;
    position: relative !important;
    left: 5%;
    width: 100%;
    margin-top: 2em;
}

.header {
    height: 6em;
    background-color: var(--text-color) !important;
}

.main-content-header-h2 {
    font-family: "Montserrat";
    text-align: left;
    padding-left: 5%;
    color: var(--text-color);
}

.code-hightlight-error {
    background-color: #ec5d5d;
    color: black;
}

.diff-sum-rt-div {
    position: relative;
    left: 5%;
    width: max-content;
    top: 2em;
    text-align: left
}

#title-bar {
    background-color: #2e025a !important;
}

.cus-lnk {
    text-decoration: none;
    color: white;
    font-size: 15px;
    margin: 4em;
}
.txt-col {
    color: var(--text-color) !important;
}

.ctus-txt {
    height: 200px !important;
    width: 100% !important;
    min-width: unset;
    min-height: unset;
    left: unset;
}

.ctus-ip {
    background-color: #cad3ff;
    border: none;
    border-radius: 20px;
    padding: 25px;
}

.ctus-div {
    width: 80%;
    position: relative;
    top: 20%;
}

footer {
    position: relative;
    top: 10%;
    text-align: center;
    background-color: var(--text-color);
    padding: 1em;
}

.desc-p {
    position: relative;
    padding-left: 5%;
    width: 95%;
    text-align: left;
}

.side-bar-col {
    position: absolute;
    top: 0px;
    left: 100%;
    color: black;
}

.page-desc {
    margin-top: 3em;
    font-size: 20px;
    line-height: 35px;
}

.desc-h2 {
    font-weight: 700;
    color: #2e025a;
}

.desc-ln-mr-lnk {
    position: relative;
    display: block;
    width: 100%;
    text-decoration: none;
    font-size: 20px;
    padding-top: 2%;
    color: var(--text-color);
}

.desc-root {
    width: 80%;
    left: 5%;
    position: relative;
    margin-top: 15%;
}

a[target="_blank"]:after {
    content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAQElEQVR42qXKwQkAIAxDUUdxtO6/RBQkQZvSi8I/pL4BoGw/XPkh4XigPmsUgh0626AjRsgxHTkUThsG2T/sIlzdTsp52kSS1wAAAABJRU5ErkJggg==);
    margin: 0 3px 0 5px;
}

.tools-menu {
    background-image: linear-gradient(to bottom, #5c5c5c, #2e2e2e);
    /* background-color: var(--code-result-header-background); */
    text-align: right;
    padding-bottom: 1%;
}

.search-root {
    position: absolute !important;
    z-index: 2;
    left: 65%;
    background-color: white;
    padding: 2%;
    border-radius: 5px;
    box-shadow: 0px 0px 20px 4px gray;
    padding-top: 1%;
    padding-bottom: 0.6%;
    padding-right: 1%;
}

.srch-ip {
    font-size: 15px;
}

.srch-btn {
    margin-left: 15px;
    background-color: green !important;
    padding: 0px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
}

#hl-pre > span {
    background-color: rgb(255, 211, 154);
}

.tb-item {
    margin-right: 1em;
    cursor: pointer;
}

.json-compare-div > div {
    width: 53% !important;
}